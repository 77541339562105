import React from 'react'

import IsHomePT from './us/index'

const HomePT = () => {
  const lang = 'pt'

  return (
    <IsHomePT lang={lang} />
  )
}

export default HomePT
